<template>
  <div class="row d-flex justify-content-end">
    <!-- display pc < 5 -->

    <div
      v-if="countPagination <= 7"
      class="col-sm-12 d-none d-sm-none d-lg-block mt-3 margin-phone"
    >
      <ul class="pagination pagination-sm">
        <li class="me-2">
          <a
            class="card-custom2"
            @click="hoverNumber === 1 ? '' : changePage(hoverNumber - 1)"
            :style="parseInt(hoverNumber) === 1 ? '' : 'cursor:pointer'"
            ><i
              class="bi bi-caret-left-fill"
              :style="
                parseInt(hoverNumber) === 1
                  ? 'color: Gainsboro;'
                  : 'color: grey;cursor:pointer'
              "
            ></i
          ></a>
        </li>

        <!-- page  -->
        <li v-for="n in countPagination" :key="n">
          <a
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            >{{ n }}
          </a>
        </li>
        <!-- page -->

        <li class="ms-2">
          <a
            class="card-custom2"
            @click="
              hoverNumber === countPagination ? '' : changePage(hoverNumber + 1)
            "
            :style="hoverNumber === countPagination ? '' : 'cursor:pointer'"
            ><i
              class="bi bi-caret-right-fill"
              :style="
                hoverNumber === countPagination
                  ? 'color: Gainsboro'
                  : 'color: grey;cursor:pointer'
              "
            ></i
          ></a>
        </li>
      </ul>
    </div>
    <!-- display pc < 5 -->

    <!-- display pc > 8-->
    <div
      v-if="countPagination > 7"
      class="col-sm-12 d-none d-sm-none d-lg-block mt-3 margin-phone"
    >
      <ul class="pagination pagination-sm">
        <li class="me-2">
          <a
            class="card-custom2"
            @click="hoverNumber === 1 ? '' : changePage(hoverNumber - 1)"
            :style="parseInt(hoverNumber) === 1 ? '' : 'cursor:pointer'"
            ><i
              class="bi bi-caret-left-fill"
              :style="
                parseInt(hoverNumber) === 1
                  ? 'color: Gainsboro;'
                  : 'color: grey;cursor:pointer'
              "
            ></i
          ></a>
        </li>

        <!-- page  -->
        <li v-for="n in countPagination" :key="n">
          <a
            v-if="
              n < 4 &&
              showPerPage === 10 &&
              (hoverNumber === countPagination - 2 ||
                hoverNumber === countPagination - 1 ||
                hoverNumber === countPagination)
            "
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            >{{ n }}
          </a>

          <a
            v-if="hoverNumber >= 5 && n === 1"
            class="card-custom1 me-1 ms-1"
            @click="changePage(1)"
            :style="
              hoverNumber === 1
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            :hidden="
              hoverNumber === countPagination - 2 ||
              hoverNumber === countPagination - 1 ||
              hoverNumber === countPagination
            "
            >{{ 1 }}
          </a>

          <!-- ... -->
          <a
            v-if="hoverNumber >= 5 && n === hoverNumber - 3"
            class="me-3 ms-3"
            :hidden="hoverNumber >= countPagination - 3 ? true : false"
            >...</a
          >

          <!-- must to show -->
          <a
            v-if="n <= hoverNumber + 2 && hoverNumber < 5 && n < 6"
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            :hidden="hoverNumber > 5 ? true : false"
            >{{ n }}
          </a>

          <!-- ... -->
          <a
            v-if="hoverNumber < 6 && n === hoverNumber + 4"
            class="me-3 ms-3"
            :hidden="hoverNumber >= 5 ? true : false"
            >...</a
          >

          <!-- -->
          <a
            v-if="
              hoverNumber >= 5 &&
              n > 3 &&
              n <= countPagination - 3 &&
              n <= countPagination &&
              n <= hoverNumber + 1 &&
              n >= hoverNumber + -1
            "
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            :hidden="
              hoverNumber < 5
                ? true
                : hoverNumber > countPagination - 2
                ? true
                : false
            "
            >{{ n }}
          </a>

          <!--  -->
          <a
            v-if="
              hoverNumber >= countPagination - 3 &&
              n > countPagination - 3 &&
              parseInt(showPerPage) === 10
            "
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            >{{ n }}
          </a>

          <!-- ... -->
          <a
            v-if="hoverNumber >= 5 && n === hoverNumber + 3"
            class="me-3 ms-3"
            :hidden="hoverNumber >= countPagination - 3 ? true : false"
            >...</a
          >

          <!-- ... -->
          <a
            v-if="hoverNumber >= countPagination - 3 && n === hoverNumber - 3"
            class="me-3 ms-3"
            >...</a
          >

          <!-- 222 -->
          <a
            v-if="
              parseInt(showPerPage) === 10 &&
              (n === countPagination - 2 || n === countPagination - 1)
            "
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            :hidden="
              hoverNumber >= 3 ? true : showPerPage === 50 ? true : false
            "
            >{{ n }}
          </a>

          <!-- -->
          <a
            v-if="n === countPagination"
            :class="
              n < 10 ? 'card-custom1 me-1 ms-1' : 'card-custom2 me-1 ms-1'
            "
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            :hidden="hoverNumber >= countPagination - 3 ? true : false"
            >{{ n }}
          </a>
        </li>
        <!-- page -->

        <li class="ms-2">
          <a
            class="card-custom2"
            @click="
              hoverNumber === countPagination ? '' : changePage(hoverNumber + 1)
            "
            :style="hoverNumber === countPagination ? '' : 'cursor:pointer'"
            ><i
              class="bi bi-caret-right-fill"
              :style="
                hoverNumber === countPagination
                  ? 'color: Gainsboro'
                  : 'color: grey;cursor:pointer'
              "
            ></i
          ></a>
        </li>
      </ul>
    </div>
    <!-- display pc > 8 -->

    <!-- display phone -->
    <div class="col-sm-12 d-sm-block d-lg-none mt-3 margin-phone">
      <ul class="pagination pagination-sm">
        <li class="me-2">
          <a
            class="card-custom2"
            @click="hoverNumber === 1 ? '' : changePage(hoverNumber - 1)"
            :style="parseInt(hoverNumber) === 1 ? '' : 'cursor:pointer'"
            ><i
              class="bi bi-caret-left-fill"
              :style="
                parseInt(hoverNumber) === 1
                  ? 'color: Gainsboro;'
                  : 'color: grey;cursor:pointer;'
              "
            ></i
          ></a>
        </li>

        <!-- page  -->
        <li v-for="n in countPagination" :key="n">
          <a
            v-if="
              n === hoverNumber ||
              n === hoverNumber - 1 ||
              n === hoverNumber - 2 ||
              n === hoverNumber + 1 ||
              n === hoverNumber + 2
            "
            :class="n < 10 ? 'card-custom1' : 'card-custom2'"
            @click="changePage(n)"
            :style="
              hoverNumber === n
                ? 'cursor: pointer; background-color: DodgerBlue; color: white'
                : 'cursor: pointer;color: grey ;'
            "
            >{{ n }}
          </a>
        </li>
        <!-- page  -->

        <li class="ms-2">
          <a
            class="card-custom2"
            @click="
              hoverNumber === countPagination ? '' : changePage(hoverNumber + 1)
            "
            id="clickNext"
            :style="hoverNumber === countPagination ? '' : 'cursor:pointer'"
            ><i
              class="bi bi-caret-right-fill"
              :style="
                hoverNumber === countPagination
                  ? 'color: Gainsboro;'
                  : 'color: grey;cursor:pointer;'
              "
            ></i
          ></a>
        </li>
      </ul>
    </div>
    <!-- display phone -->

    <!-- <div class="col-sm-4">
      <div class="row d-flex justify-content-end">
        <div class="col-6 col-md-5 col-lg-4">
          <select v-model="showPerPage" class="form-select">
            <option selected>10</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
            <option>1000</option>
          </select>
        </div>
        <div class="col-6 col-md-7 mt-3 text-end text-md-start">
          showing {{ showing1 }}-{{ showing2 }} of {{ items.length }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data: () => ({
    showPerPage: 10,
    showing1: 1,
    showing2: 10,
    countPagination: 1,
    dataItemsShow: [],
    hoverNumber: 1,
    checkbox: [],
    newCheckbox: [],
  }),
  watch: {
    showPerPage(val) {
      if (val) {
        this.filterItems(parseInt(val));
        this.setCountPagination(parseInt(val));
        this.hoverNumber = 1;
        this.checkAll = false;
        this.checkbox = [];
        if (val > this.items.length) {
          this.overShowPerPage();
        } else {
          this.showing1 = 1;
          this.showing2 = val;
        }
      }
    },

    $route(to, from) {
      if (to) {
        if (this.$route.query.get === "1") {
          let n = 0;
          for (n = 1; n < this.$route.query.page; n++) {
            document.getElementById("clickNext").click();
          }
        }
      }
    },
    "items.length"(val) {
      if (val) {
        this.setShowNumber();
        this.setCountPagination(this.showPerPage);
      }
    },
  },
  async mounted() {
    await this.setCountPagination(this.showPerPage);
    await this.filterItems();
    await this.setShowNumber();
  },
  methods: {
    //--- Pagination --- start
    setShowNumber() {
      if (this.showing2 > this.items.length) {
        this.showing2 = this.items.length;
      }
    },

    filterItems(val) {
      if (val) {
        this.dataItemsShow = this.items.slice(0, val);
        this.$emit("filterItems", this.dataItemsShow);
      } else {
        this.dataItemsShow = this.items.slice(0, 10);
        this.$emit("filterItems", this.dataItemsShow);
      }
    },

    setCountPagination(val) {
      if (parseInt(this.items.length / val) < 1) {
        this.countPagination = 1;
      } else {
        this.countPagination = Math.ceil(this.items.length / val);
      }
    },

    changePage(n) {
      this.$router.push({
        query: {
          page: n,
        },
      });
      this.hoverNumber = n;
      this.handleChangePage(n);
    },

    handleChangePage(val) {
      let i = 0;
      let j = parseInt(this.showPerPage);
      let k = 0;

      for (i; i < val; i++) {
        if (i === 0) {
          this.dataItemsShow = this.items.slice(0, j);
          this.showing1 = j - parseInt(this.showPerPage) + 1;
          this.showing2 = this.showing1 + parseInt(this.showPerPage) - 1;
          this.$emit("handleChangePage", this.dataItemsShow, this.showing1);
        } else {
          this.dataItemsShow = this.items.slice(
            (k += parseInt(this.showPerPage)),
            (j += parseInt(this.showPerPage))
          );
          this.showing1 = j - parseInt(this.showPerPage) + 1;
          this.showing2 = this.showing1 + parseInt(this.showPerPage) - 1;
          this.$emit("handleChangePage", this.dataItemsShow, this.showing1);
        }
      }
      if (val === this.countPagination) {
        this.showing2 = this.items.length;
      }
    },

    overShowPerPage() {
      this.showing2 = this.items.length;
    },
    //--- Pagination --- end
  },
};
</script>

<style scoped>
.card-custom1 {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 10px 13.5px 10px 13.5px;
  border-radius: 5px;
  font-weight: bold;
}

.card-custom2 {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  font-weight: bold;
}

/* On mouse-over, add a deeper shadow */
.card-custom1:hover {
  box-shadow: 0 2.5px 4.5px 0 rgba(0, 0, 0, 0.2);
}

.card-custom2:hover {
  box-shadow: 0 2.5px 4.5px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
@media only screen and (max-width: 600px) {
  .margin-phone {
    margin-bottom: 30px;
  }
}
</style>
