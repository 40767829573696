<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading" style="background-color: transparent">
    <div v-if="$route.query.id" class="row mb-5">
      <div class="col-12 col-sm-5 col-md-5 col-lg-5">
        <button
          v-if="form.status !== '0'"
          type="button"
          class="btn btn-light shadow-sm"
          style="font-size: 14px; font-weight: bold; color: black"
          @click="goBack()"
        >
          กลับ
        </button>
      </div>

      <div
        class="col-12 col-sm-7 col-md-7 col-lg-7 mt-4 mt-md-0 mt-lg-0 text-center text-md-start"
      >
        <div
          v-if="form.is_cancel === '1'"
          style="color: #b22222; font-weight: bold; font-size: 20px"
        >
          - ยกเลิกแล้ว -
        </div>
      </div>
    </div>

    <form id="formItems" class="needs-validation" novalidate>
      <!-- form -->
      <div class="row">
        <div class="col-sm-7 mb-4">
          <div class="card shadow-sm">
            <div class="card-body">
              <!-- -->
              <div class="row text-sm-start d-flex justify-content-center mb-4">
                <div class="col-sm-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><i class="bi bi-search" style="font-size: 22px"></i
                      ></span>
                    </div>
                    <input
                      v-model="searchProduct"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="13"
                      type="text"
                      class="form-control"
                      placeholder="ค้นหา Item code / Barcode"
                      aria-describedby="basic-addon1"
                      :disabled="isView"
                      @keypress.enter.prevent="addProductItems()"
                    />
                    <!-- <input
                      v-model="searchProduct"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="13"
                      type="text"
                      class="form-control"
                      placeholder="ค้นหา Item code / Barcode"
                      aria-describedby="basic-addon1"
                      @keypress.enter.prevent="addProductItems()"
                      :disabled="isView"
                    /> -->
                    <button
                      :disabled="isView ? true : loadingAddPd ? true : false"
                      @click.prevent="addProductItems()"
                      class="btn btn-light btn-primary"
                      style="font-weight: bold"
                    >
                      <div
                        v-if="loadingAddPd"
                        class="spinner-grow spinner-grow-sm text-light"
                        role="status"
                      ></div>
                      เพิ่ม
                    </button>
                    <!-- <button
                      :disabled="isView ? true : loadingAddPd ? true : false"
                      @click="addProductItems()"
                      class="btn btn-light btn-primary"
                      style="font-weight: bold"
                    >
                      <div
                        v-if="loadingAddPd"
                        class="spinner-grow spinner-grow-sm text-light"
                        role="status"
                      ></div>
                      เพิ่ม
                    </button> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="table-responsive">
                  <table
                    class="table table-hover table-rounded table-striped border gy-7 gs-7"
                  >
                    <thead>
                      <tr
                        style="width: 100%"
                        class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
                      >
                        <th style="width: 50%" class="text-center">Item</th>
                        <th style="width: 15%" class="text-center">item_amt</th>
                        <th style="width: 25%" class="text-center">Price</th>
                        <th style="width: 10%"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, index) in productItems"
                        :key="index"
                        data-toggle="tooltip"
                        :title="item.searchBy"
                      >
                        <td>{{ item.product_name }}</td>
                        <td>
                          <input
                            v-model="item.item_amt"
                            @input="addQtyByIndex(index, item.item_amt)"
                            class="form-control"
                            type="number"
                            min="1"
                            oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                            :disabled="isView"
                          />
                        </td>
                        <td class="text-end">
                          {{
                            item.product_promotion_price
                              ? item.product_promotion_price
                              : item.product_retail_price
                          }}
                          <span
                            v-if="item.product_promotion_price"
                            style="color: red"
                          >
                            (PROMO.)</span
                          >
                        </td>
                        <td>
                          <div class="row">
                            <div class="col-sm-12 text-end">
                              <button
                                type="button"
                                class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                                @click="goToDeleteProductItem(item)"
                                :disabled="isView"
                              >
                                <i class="bi bi-trash3-fill"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr style="color: LightGrey" />

            <div class="ps-9 pb-5" style="font-size: 16px; color: grey">
              สินค้าจำนวน
              <b style="color: black">{{ this.productItems.length }}</b> รายการ
            </div>
          </div>
        </div>

        <div class="col-sm-5">
          <div class="card shadow-sm">
            <div class="card-body pb-2">
              <!-- -->
              <div class="row text-sm-start d-flex justify-content-center mb-4">
                <div class="col-sm-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><i
                          class="bi bi-person-plus-fill"
                          style="font-size: 22px"
                        ></i
                      ></span>
                    </div>
                    <input
                      v-model="searchCustomer"
                      type="text"
                      class="form-control"
                      placeholder="ค้นหาชื่อผู้ซื้อ"
                      aria-describedby="basic-addon2"
                      ref="inputCustomer"
                      id="inputCustomer"
                      @keypress.enter.prevent="addCustomer()"
                      :disabled="isView ? true : loadingAddCs ? true : false"
                      list="datalistOptions"
                    />
                    <datalist
                      v-if="searchListCustomer.length > 1"
                      id="datalistOptions"
                    >
                      <option
                        v-for="(item, index) in searchListCustomer"
                        :key="index"
                        :value="item.customer_code"
                      >
                        {{ item.customer_name }} | {{ item.customer_tel }}
                      </option>
                    </datalist>

                    <button
                      @click="addCustomer()"
                      :class="
                        isView
                          ? 'btn-select-customer-disabled'
                          : loadingAddCs
                          ? 'btn-select-customer-disabled'
                          : 'btn-select-customer'
                      "
                      :disabled="isView ? true : loadingAddCs ? true : false"
                      type="button"
                    >
                      <div
                        v-if="loadingAddCs"
                        class="spinner-grow spinner-grow-sm text-light"
                        role="status"
                      ></div>
                      เลือก
                    </button>
                  </div>
                </div>

                <div
                  v-if="this.customerDetails"
                  class="col-sm-12 mt-4"
                  style="font-size: 16px"
                >
                  คุณ <b>{{ customerDetails.customer_name }}</b> | โทร :
                  <b>{{ customerDetails.customer_tel }}</b>
                </div>

                <hr style="color: LightGrey" class="mt-7 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    มูลค่ารวมก่อนภาษี
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.total_price_before_vat.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    ภาษี
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.vat.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    มูลค่า
                  </div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.total_price_summary.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />

                <div
                  class="row pb-4"
                  style="
                    background-color: LightGrey;
                    border-radius: 7px;
                    font-size: 20px;
                    font-weight: bold;
                  "
                >
                  <div class="col-sm-6 mt-4 text-start">มูลค่ารวมสุทธิ</div>

                  <div class="col-sm-6 mt-4 text-end" style="font-size: 16px">
                    {{ form.final_price.toFixed(2) }}
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-7 mb-3 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-4 mt-4 text-start" style="font-size: 16px">
                    รับเงินสด
                  </div>

                  <div class="col-sm-8 mt-4 text-end" style="font-size: 16px">
                    <input
                      v-model="form.cash"
                      @input="handleCash(form.cash)"
                      class="form-control text-end"
                      type="number"
                      min="0"
                      oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                      :disabled="isView"
                    />
                  </div>
                </div>

                <hr style="color: LightGrey" class="mt-7 mb-0 pb-0" />

                <div class="row" style="color: grey">
                  <div class="col-sm-6 mt-4 text-start" style="font-size: 16px">
                    เงินทอน
                  </div>

                  <div
                    class="col-sm-6 mt-4 text-end"
                    style="font-size: 16px; font-weight: bold; color: black"
                  >
                    {{ form.change.toFixed(2) }}
                  </div>
                </div>

                <button
                  @click.prevent="handleApprove"
                  type="submit"
                  class="btn btn-lg btn-light btn-primary mt-10"
                  style="font-size: 20px; font-weight: bold"
                  :disabled="
                    isView
                      ? true
                      : productItems.length < 0
                      ? true
                      : form.cash < form.final_price
                      ? true
                      : false
                  "
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div v-if="isShortExport">
      <shortPdfFile
        :dataShortExport="dataShortExport"
        :type="1"
        @generatedShortFile="generatedShortFile"
      />
    </div>

    <DialogConfirmByPass
      :productItemData="productItemData"
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import saleApi from "@/api/sale/";
import whApi from "@/api/warehouse/";
import apiConfig from "@/config/api";

import Loader from "./components/loaderPOS.vue";
import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";

import { Decode, Encode } from "@/services";

import shortPdfFile from "../../components/files/shortPosFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    Loader,
    shortPdfFile,
  },
  setup() {
    document.title = "BELUCA | POS";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    loadingAddPd: false,
    loadingAddCs: false,

    item_amt: 1,

    isShortExport: false,
    loadingShortExport: false,
    indexShortExport: "",

    searchProduct: "",
    searchCustomer: "",
    searchBy: "",

    customerDetails: "",

    isSubmit: false,
    dialogConfirmByPass: false,

    form: {
      selectMainProduct: "",
      selectSecondaryProduct: "",
      ProductName: "",
      detail: "",
      barcode: "",
      pog: "",
      retailPrice: "",
      proPrice: "",
      wholesalePrice: "",
      min: "",
      max: "",

      is_cancel: "0",

      cash: 0,
      change: 0,

      customerId: "",
      customer_code: "",

      products: [],

      total_price_before_vat: 0,
      vat: 0,
      total_price_summary: 0,
      final_price: 0,
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    customers: [],
    products: [],

    productItems: [],
    isView: false,

    searchListCustomer: [],
    productItemData: [],
  }),

  watch: {
    searchProduct(val) {
      if (val.length === 13) {
        this.loadingAddPd = true;
        setTimeout(() => {
          this.searchPdByBarcode();
        }, 500);
      }
    },

    searchCustomer(val) {
      if (val.length === 10) {
        this.loadingAddCs = true;
        setTimeout(() => {
          this.onSearchCustomer();
        }, 500);
      }
      if (val.length === 0) {
        this.searchListCustomer = [];
      }
    },

    $route(val) {
      this.$route.query.id ? (this.isView = true) : (this.isView = false);
      if (Object.keys(val.query).length === 0) {
        this.loading = true;
        setTimeout(() => {
          this.clearForm();
        }, 300);
      } else {
      }
    },
  },

  async created() {
    await this.getAllCustomer();
    await this.getOne();
    this.$route.query.id ? (this.isView = true) : (this.isView = false);
  },

  methods: {
    setDefaultCustomer(items) {
      if (items) {
        const findCustomer = items.find(
          (data) => data.customer_company_type === "บุคคลธรรมดา"
        );
        if (findCustomer) {
          this.searchCustomer = findCustomer.customer_code;
          this.addCustomer();
        }
      }
    },
    async getAllCustomer() {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await saleApi.customer.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.customers = responseData.data;
        this.$route.query.id ? "" : this.setDefaultCustomer(responseData.data);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    goBack() {
      this.$router.go(-1);
    },

    addProductItems(item, method) {
      if (item) {
        const newItem = item[0];
        this.productItems.push({
          ...newItem,
          item_amt: this.item_amt,
          item_promo_price: newItem.product_promotion_price,
          item_price: newItem.product_retail_price,
          searchBy: `ค้นหาโดย ${method}`,
        });
        this.sumPayout();
        this.form.cash = 0;
        this.form.change = 0;
      } else {
        if (this.searchProduct.length === 0) {
          this.loadingAddPd = true;
          setTimeout(() => {
            this.searchPdAll();
          }, 500);
        } else {
          if (this.searchProduct.length === 13) {
            this.loadingAddPd = true;
            setTimeout(() => {
              this.searchPdByBarcode();
            }, 500);
          }
          if (
            this.searchProduct.length >= 5 ||
            this.searchProduct.length <= 12
          ) {
            this.loadingAddPd = true;
            setTimeout(() => {
              this.searchPdByCode();
            }, 500);
          }
        }
      }
    },

    addCustomer(item) {
      if (item) {
        const newItem = item[0];
        this.customerDetails = newItem;
        this.form.customerId = newItem.id;
      } else {
        // if (this.searchCustomer.length === 9) {
        this.loadingAddCs = true;
        setTimeout(() => {
          this.onSearchCustomer();
        }, 500);
        // }
      }
    },

    goToDeleteProductItem(item) {
      Swal.fire({
        icon: "question",
        html: `<span style="font-size: 16px">ต้องการลบสินค้ารายการ <b style="font-size: 18px">${item.product_name}</b> หรือไม่ ?</span>`,
        showCancelButton: true,
        width: 450,
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmDeleteProductItem(item);
        }
      });
    },
    confirmDeleteProductItem(item) {
      const indexOfItem = this.productItems.indexOf(item);
      this.productItems.splice(indexOfItem, 1);
      this.sumPayout();
    },

    addQtyByIndex(index, item_amt) {
      this.productItems[index] = {
        ...this.productItems[index],
        item_amt: item_amt,
      };
      this.sumPayout();
      this.handleCash(this.form.cash);
    },

    sumPayout() {
      let total = 0;
      let vat = 0;

      this.productItems.forEach((element) => {
        if (element.product_retail_price && element.item_amt) {
          total +=
            parseInt(
              element.product_promotion_price
                ? element.product_promotion_price
                : element.product_retail_price
            ) * parseInt(element.item_amt);
          vat +=
            (parseInt(
              element.product_promotion_price
                ? element.product_promotion_price
                : element.product_retail_price
            ) *
              parseInt(element.item_amt) *
              7) /
            100;
        }
      });

      this.form.total_price_before_vat = total - vat;
      this.form.vat = vat;
      this.form.total_price_summary = total;
      this.form.final_price = total;
    },

    handleCash(val) {
      if (val && val >= this.form.final_price) {
        this.form.change = parseFloat(val) - parseFloat(this.form.final_price);
      } else {
        this.form.change = 0;
      }
    },

    handleApprove(item) {
      if (!this.searchCustomer) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณาเลือกผู้ซื้อ",
        });
      } else if (this.form.final_price === 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "มีรายการสินค้าไม่ได้กรอก item_amt",
        });
      } else if (this.form.cash === 0) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "กรุณากรอกช่องรับเงินสด",
        });
      } else if (this.form.cash < this.form.final_price) {
        Swal.fire({
          icon: "warning",
          title: "ชำระเงินไม่สำเร็จ !",
          text: "มูลค่าเงินสดต้องมากกว่ามูลค่ารวมสุทธิ",
        });
      } else {
        this.submit();

        // this.dialogConfirmByPass = true;
      }
    },

    async setDataToDialog(data) {
      data.forEach((element) => {
        this.productItemData.push({
          ...element,
          // img: apiConfig.ENDPOINT + element.files[0].path,
          img: 'https://poonework-service.com/spareParts/302MY93025/20240820-094819-E1947.wh.jpg'
        });
      });

      return true;
    },

    async openDialogProduct(data) {
      const responseSet = await this.setDataToDialog(data);
      if (responseSet) {
        this.dialogConfirmByPass = true;
      }
    },

    async submitConfirmPass(item) {
      item.forEach((element) => {
        this.addProductItems([element]);
      });
      this.closeDialogConformByPass();
    },
    closeDialogConformByPass() {
      this.productItemData = [];
      this.dialogConfirmByPass = false;
    },
    async submit() {
      const validAmount = this.checkProductQty();

      if (validAmount) {
        const companyId = localStorage.getItem("companyId");
        const companyBranchId = localStorage.getItem("companyBranchId");

        let responseData = [];
        let userId = Decode.decode(localStorage.getItem("boss"));

        this.validateForm();

        this.form.products = this.productItems;

        this.form = {
          ...this.form,
          companyId: companyId,
          companyBranchId: companyBranchId,
          userId: userId,
        };

        try {
          responseData = await saleApi.pos.create(this.form);
        } catch (error) {}
        if (responseData.response_status === "SUCCESS") {
          this.updateProduct();
          Swal.fire({
            icon: "success",
            title: "ชำระค่าสินค้าเรียบร้อยแล้ว",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "พิมพ์ใบเสร็จ",
            cancelButtonText: "ขายบิลต่อไป",
          }).then((result) => {
            if (result.isConfirmed) {
              this.form = {
                ...this.form,
                point_of_sale_no: responseData.data.point_of_sale_no,
                company: responseData.data.company,
                companyBranch: responseData.data.companyBranch,
                pointOfSaleItems: responseData.data.pointOfSaleItems,
              };
              this.goToShortExport(this.form);
            } else {
              this.isShortExport = false;
            }
            this.clearForm();
          });
        }
      }
    },

    checkProductQty() {
      const result = Object.values(
        this.productItems.reduce((acc, { id, item_amt, ...rest }) => {
          acc[id] = {
            id,
            item_amt: (acc[id] ? acc[id].item_amt : 0) + item_amt,
            ...rest,
          };
          return acc;
        }, {})
      );

      let returnResult = 1;

      result.forEach((element) => {
        if (element.item_amt > element.product_bring_qty) {
          returnResult *= 0;
          Swal.fire({
            icon: "error",
            title: "ชำระเงินไม่สำเร็จ !",
            html: `<div>คลังสินค้ามีจำนวนสินค้ารายการ <strong style='font-size: 18px'>${element.product_name}</strong> น้อยกว่าจำนวนที่จะขาย</div>`,
            confirmButtonText: "ตกลง",
          });
        } else {
          returnResult *= 1;
        }
      });

      return returnResult === 0 ? false : true;
    },

    // update item
    async updateProduct() {
      let countArr = 0;
      const updateResponse = await whApi.product.multiUpdate(
        this.form.products,
        "pos"
      );

      if (updateResponse.response_status === "ERROR") {
        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      }
    },

    // update item

    clearForm() {
      this.form = {
        selectMainProduct: "",
        selectSecondaryProduct: "",
        ProductName: "",
        detail: "",
        barcode: "",
        pog: "",
        retailPrice: "",
        proPrice: "",
        wholesalePrice: "",
        min: "",
        max: "",

        cash: 0,
        change: 0,

        is_cancel: "0",

        total_price_before_vat: 0,
        vat: 0,
        total_price_summary: 0,
        final_price: 0,
      };

      this.searchProduct = "";
      this.searchCustomer = "";
      this.customerDetails = "";

      this.productItems = [];

      this.getAllCustomer();
    },

    // view
    async getOne() {
      if (this.$route.query.id) {
        this.loading = true;
        let responseData = [];

        try {
          responseData = await saleApi.pos.getOne(this.$route.query.id);
        } catch (error) {
          console.log(error);
        }
        if (responseData.response_status === "SUCCESS") {
          this.loading = false;

          this.form = {
            ...responseData.data,
            total_price_before_vat: parseFloat(
              responseData.data.total_price_before_vat
            ),
            vat: parseFloat(responseData.data.vat),
            total_price_summary: parseFloat(
              responseData.data.total_price_summary
            ),
            final_price: parseFloat(responseData.data.final_price),
            change: parseFloat(responseData.data.change),
          };

          this.customerDetails = responseData.data.customer;

          this.setProductByGetOne(responseData.data.pointOfSaleItems);
        } else {
          this.loading = false;

          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          });
        }
      }
    },

    setProductByGetOne(item) {
      item.forEach((element) => {
        this.productItems.push({
          ...element.product,
          item_amt: element.item_amt,
        });
      });
    },

    // search product
    async searchPdAll() {
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      responseSearch = await whApi.product.getAll(
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
        }
        // "1",
        // "code"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        // this.addProductItems(responseSearch.data, "Code");
        this.loadingAddPd = false;
        // this.searchProduct = "";
        this.openDialogProduct(responseSearch.data);
      } else {
        Swal.fire({
          icon: "error",
          title: `ไม่พบข้อมูลสินค้า !`,
          confirmButtonText: "ตกลง",
          width: 450,
          timer: 2000,
        }).then((result) => {
          this.loadingAddPd = false;
        });
      }
    },

    async searchPdByCode() {
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      responseSearch = await whApi.product.search(
        {
          search: this.searchProduct,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
          pos: "1",
          search_by: "code",
        }
        // "1",
        // "code"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        // this.addProductItems(responseSearch.data, "Code");
        this.loadingAddPd = false;
        // this.searchProduct = "";
        this.openDialogProduct(responseSearch.data);
      } else {
        this.searchPdByBarcode();
      }
    },

    async searchPdByBarcode() {
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      responseSearch = await whApi.product.search(
        {
          search: this.searchProduct,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
          pos: "1",
          search_by: "barcode",
        }
        // "1",
        // "barcode"
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 0
      ) {
        // this.addProductItems(responseSearch.data, "Barcode");
        this.loadingAddPd = false;
        // this.searchProduct = "";
        this.openDialogProduct(responseSearch.data);
      } else {
        Swal.fire({
          icon: "error",
          title: `ไม่พบข้อมูลสินค้า !`,
          confirmButtonText: "ตกลง",
          width: 450,
          timer: 2000,
        }).then((result) => {
          this.loadingAddPd = false;
        });
      }
    },

    //search customer
    async onSearchCustomer() {
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      responseSearch = await saleApi.customer.search(
        {
          search: this.searchCustomer,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
          pos: "1",
        }
      );

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length === 1
      ) {
        this.loadingAddCs = false;
        this.addCustomer(responseSearch.data);
        this.form.customer_code = responseSearch.data[0].customer_code;
        setTimeout(() => {
          this.$refs.inputCustomer.focus();
        }, 100);
      }
      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length > 1
      ) {
        this.searchListCustomer = responseSearch.data;
        this.loadingAddCs = false;
        setTimeout(() => {
          this.$refs.inputCustomer.focus();
        }, 100);
      }

      if (
        responseSearch.response_status === "SUCCESS" &&
        responseSearch.data.length < 1
      ) {
        this.searchListCustomer = [];
        this.loadingAddCs = false;
        setTimeout(() => {
          this.$refs.inputCustomer.focus();
        }, 100);
        Swal.fire({
          icon: "error",
          html: `<h4>ไม่พบลูกค้าที่ค้นหาด้วย <b>${this.searchCustomer}</b></h4>`,
          confirmButtonText: "ตกลง",
        });
      }
    },

    // Short export file
    goToShortExport(item, index) {
      this.dataShortExport = item;
      // this.indexShortExport = index;
      this.loadingShortExport = true;
      this.isShortExport = true;

      this.loadingExport = false;
    },
    generatedShortFile() {
      this.isShortExport = false;
    },
    hoverShortExportBtn() {
      this.loadingShortExport = false;
      this.isShortExport = false;
    },
    // Short export file
  },
};
</script>

<style scoped>
.btn-select-customer {
  font-weight: bold;
  background-color: #3699ff;
  color: white;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  width: 68px;
  border: 0px;
}

.btn-select-customer:hover {
  font-weight: bold;
  background-color: #0097d2;
  color: white;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  width: 68px;
  border: 0px;
}
.btn-select-customer-disabled {
  font-weight: bold;
  background-color: #85c0ff;
  color: white;
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  width: 68px;
  border: 0px;
}
</style>
